import React, { PropsWithChildren } from "react";
import { IntlProvider } from "react-intl";

export interface IProps {
    messages: object
    defaultLocale: string
}

export const getBrowerLanguage = () => {
    return navigator.language.split(/[-_]/)[0];
}

const LanguageProvider: React.FC<IProps> = (props: PropsWithChildren<IProps>) => {
    const { children, messages, defaultLocale } = props;

    /**
     * Detect language from browser
     * - if missing translations for specific language, default message will be used as fallback
     */
    const language = getBrowerLanguage();

    return (
        <IntlProvider locale={language} messages={messages[defaultLocale]} defaultLocale={defaultLocale}>
            {children}
        </IntlProvider>
    );
};

export default LanguageProvider;
